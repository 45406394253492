<template>
  <div class="notification is-dark has-padding-150">
    <div class="media">
      <div class="media-left">
        <b-icon icon="pause" size="is-medium" class="has-margin-top-25" />
      </div>
      <div class="media-content">
        <p>
          <strong>This task has been queued!</strong>
          <br />
          As there is currently another open task
          {{ task.fqdn ? `for ${task.fqdn}` : `` }}, this one has been placed in
          a queue. As soon as the other task has been resolved we'll
          automatically release your next queued task. This process helps to
          prevent conflicts from changes made across parallel tasks.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TaskIsQueued",
  props: {
    task: {
      type: Object,
      required: true
    }
  }
};
</script>
